import { defaults } from '../../index.js';
import { gsapConfig } from '../../index.js';
import { gsap } from "gsap/dist/gsap";

window.addEventListener("DOMContentLoaded", () => {
 gsapConfig();
 animateLanding();
});

function animateLanding() {
 const headerItems = document.querySelectorAll(".header-item");
 const trigger = document.querySelectorAll(".trigger");
 const sprayWrapper = document.querySelectorAll(".spray");
 const sprayColumns = document.querySelectorAll(".spray-column");
 gsap.to(headerItems, { opacity: 1, y: 0, stagger: 0.05 });
 const tl = gsap.timeline({ repeat: 1, delay: defaults.duration * 0.5 });
 tl.to(trigger, { rotate: -15, duration: defaults.duration * 0.5});
 tl.to(trigger, { rotate: 0, duration: defaults.duration * 0.5 });
 tl.to(sprayWrapper, { translateX: -40 }, defaults.duration * 0.25);
 tl.to(sprayColumns, { opacity: 1, stagger: -0.07 }, defaults.duration * 0.25);
 tl.to(sprayColumns, { opacity: 0, stagger: -0.07 }, defaults.duration * 0.75);
 return;
}